<template>
<div class="contact" >
    <Header url="contact" />

    <div class="head">
          <div class="stitle">联系我们</div>
          <div class="stitle2">————  福楷智能科技  ————</div>
    </div>


    <div class="canbox">
        <div class="w12">
                    <div class="canitem" v-for="(item,index) in list" :key="index">
                              <div class="canittitle">{{item.title}}</div>
                              <div class="canittitle2">{{item.titlezm}}</div>
                              <div class="canittitle3">联系电话：{{item.phone}}</div>
                              <div class="canittitle3">电子邮箱：{{item.email}}</div>
                              <div class="canittitle3">办公地址：{{item.address}}</div>
                    </div>
        </div>  
    </div>





<Wxewm />
  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import './index.css'
import Header from '../../components/header'
import Wxewm from '../../components/kefuewm'
import Footer from '../../components/footer'
import { getcontact } from '../../request/api'

export default {
    name: 'Contact',
    components: {
        Header,
        Wxewm,
        Footer,
    },
    data() {
      return {
           list:[]
      }
    },
    mounted(){
        this.getdata();
    },
    methods: {
      getdata(){
        let that =this
         getcontact().then(res=>{
             that.list = res.data
         })
      }
    }


}
</script>

